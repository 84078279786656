import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Graphic from "../Graphic/default";
import SocialMediaShare from "../SocialMediaShare/default";
import graphicSVG from '../../resources/icons/svg/graphic-xs.svg';
import resultImage1 from '../../resources/assets/mainResult1.jpg';
import resultImage2 from '../../resources/assets/mainResult2.jpg';
import resultImage3 from '../../resources/assets/mainResult3.jpg';
import exampleGraphic from '../../resources/assets/examples/Example.png';
import { Icon } from '../Core/Icon/default';
import { Carousel } from "../Core/Carousel/default";
import { candidatesInfo } from '../utilities/_conf';
import axios from 'axios';
/*import Button from '../Core/Button/default';
import logoee from '../../resources/assets/logo_ee_2x.png';
import logoFiedrich from '../../resources/assets/logo_azul_fondo_transparente_2x.png';*/
/**** Nav
 * <div className="Results-Nav">
            <div className="Results-NavItem">
                <div className="Results-NavItemLabel">1/5</div>
                <Button className="Button-Next Button-Next_results Button_center"><Icon className="Icon Icon_next Icon_marginless" icon="chevron-left"></Icon></Button>
            </div>
        </div> */

const candidateData = (className = '', candidate = {}, secondary = false, all = false) => {
    //console.log("Candidate",candidate)
    return !candidate.all ? <div className={`Results-Candidate ${className || ''}`}>
        <div className="Results-CandidateImageFrame">
            {candidate && (candidate["1x1"] || (candidate[360] && candidate[600])) && <picture className=''>
                <source
                    className='Results-CandidateImage'
                    srcSet={!secondary ? candidate?.[360] : candidate?.["1x1"]}
                    media={`(max-width: 599px)`}
                />
                <source
                    className='Results-CandidateImage'
                    srcSet={!secondary ? candidate?.[600] : candidate?.["1x1"]}
                    media={`(max-width: 1200px)`}
                />
                <img
                    alt="Foto de Candidato"
                    className='Results-CandidateImage'
                    src={!secondary ? candidate?.[360] : candidate?.["1x1"]}
                />
            </picture>}
        </div>
        <div className="Results-CandidateContainer">
            <div className="Results-CandidateContent">
                <div className="Results-CandidateName">{candidate.name || ''}</div>
                <div className="Results-CandidateAge">{candidate.age || ''}</div>
                <div className="Results-CandidateParty">{candidate.party || ''}</div>
            </div>
            <div className="Results-CandidateFooter">
                <div className="Results-CandidateInfo">Este es el candidato con el que tienes un mayor nivel de coincidencia en las respuestas.</div>
            </div>
        </div>
    </div> : <div className="Results-CandidateImages">
        <picture className='Results-CandidateImagesContainer'>
            <source
                className='Results-CandidateImagesSource'
                srcSet={candidate?.[360] || ''}
                media={`(max-width: 599px)`}
            />
            <source
                className='Results-CandidateImagesSource'
                srcSet={candidate?.[600] || ''}
                media={`(max-width: 1200px)`}
            />
            <img
                alt="Foto de Candidato"
                className='Results-CandidateImagesSource'
                src={candidate?.[360] || ''}
            />
        </picture>
    </div>;
}

export const ResultsSample = () => {
    let navigate = useNavigate();
    const { state } = useLocation();
    const { result } = state || {};
    const handleClick = () => {
        navigate("/resultados", { state: { result: result } });
    }
    return <div id="Survey-PopUp"><div class="Survey-PopUp Survey-PopUp_1"></div>
        <div class="Container Container-Example">
            <div class="Survey Survey_degrade">
                <div class="Survey-PopUpIcon">
                    <svg id="Componente_60_1" data-name="Componente 60 – 1" xmlns="http://www.w3.org/2000/svg" width="82.616" height="82.616" viewBox="0 0 82.616 82.616">
                        <g id="Grupo_30732" data-name="Grupo 30732">
                            <circle id="Elipse_1179" data-name="Elipse 1179" cx="41.308" cy="41.308" r="41.308" fill="rgba(108,76,229,0.07)" />
                        </g>
                        <g id="Grupo_30743" data-name="Grupo 30743" transform="translate(35.406 14.852)">
                            <g id="Grupo_30742" data-name="Grupo 30742" transform="translate(0 17.887)">
                                <path id="Trazado_16692" data-name="Trazado 16692" d="M634.811,331.426v23.818a6.69,6.69,0,0,1-6.781,6.437c-3.674-.156-6.781-2.828-6.781-6.437V331.426a6.691,6.691,0,0,1,6.781-6.438c3.674.156,6.781,2.829,6.781,6.438Z" transform="translate(-621.25 -324.983)" fill="#6c4ce5" stroke="#6c4ce5" stroke-width="1" />
                            </g>
                            <circle id="Elipse_1181" data-name="Elipse 1181" cx="6.781" cy="6.781" r="6.781" transform="translate(0.085 0)" fill="#6c4ce5" stroke="#6c4ce5" stroke-width="1" />
                        </g>
                    </svg>
                </div>
                <div class="Survey-PopUpText Survey-PopUpText_headline">Cómo leer tus resultados</div>
                <div class="Survey-PopUpText Survey-PopUpText_a_b_option">Primero te mostraremos tu posición política. Más adelante te mostraremos el nivel de coincidencia entre tus respuestas y las de los candidatos.</div>
                <img alt={"Gráfica de ejemplo"} class="Survey-PopUpImage Survey-PopUpImage_example" src={exampleGraphic} />
                <button onClick={handleClick} class="Survey-Button Survey-Button_next Survey-Button_example" id="SurveyButtonExample">Continuar</button>
            </div>
        </div>
    </div>;
}

const getData = async (data, setResult) => {
    const config = {
        method: 'post',
        url: process.env.REACT_APP_FESCOL_API_DOMAIN,
        headers: {
            'X-API-Key': process.env.REACT_APP_FESCOL_API_KEY,
            'Content-Type': 'application/json'
        },
        data: data
    };
    try {
        const response = await axios(config);
        setResult(response?.data || {});
        const storageResultName = "survey_result_match";
        if (typeof window !== 'undefined' && response?.data) {
            window.localStorage.setItem(storageResultName, JSON.stringify(response?.data || {}));
        }
        return response?.data;
    } catch (error) {
        console.error("Error en la petición:", error);
        return null;
    }
}

const Results = (props) => {
    let navigate = useNavigate();
    const queryString = window?.location?.search;
    const urlParams = queryString ? new URLSearchParams(queryString) : null;
    const indexParameter = urlParams?.get('index') || 0;
    const [activeIndex, setActiveIndex] = useState(indexParameter);
    const [localStorageResult, setLocalStorageResult] = useState({ result: {} });
    const { state } = useLocation();
    const oldResultStorage = typeof window !== 'undefined' ? JSON.parse(window.localStorage.getItem('survey_result_match')) : null;
    const oldResult = oldResultStorage ? { result: oldResultStorage } : null;
    const initialResult = state?.result || oldResult?.result || localStorageResult.result || {};
    const resultRef = useRef(initialResult);
    const storageOldDataName = "survey_history_old";

    useEffect(() => {
        if (!resultRef.current?.similitudes) {
            const _data = window.localStorage.getItem(storageOldDataName);
            getData(_data, (newResult) => {
                resultRef.current = newResult;
                setLocalStorageResult({ result: newResult });
            });
        }
    }, []);

    useEffect(() => {
        const dataLayer = window.dataLayer || null;
        if (dataLayer) {
            dataLayer.push({ event: "page_number", eventLabel: `Resultado_slide_${activeIndex + 1}` })
        }
    }, [activeIndex]);

    const general = resultRef.current?.similitudes?.general || [];
    const economy = resultRef.current?.similitudes?.economico || [];
    const social = resultRef.current?.similitudes?.social || [];
    const total_respuestas = new Intl.NumberFormat('de-DE').format(resultRef.current?.total_respuestas || 0);
    const respuestas_similares = new Intl.NumberFormat('de-DE').format(resultRef.current?.respuestas_similares || 0);
    const ciudadanos_similares = new Intl.NumberFormat('de-DE').format(resultRef.current?.ciudadanos_similares || 0);
    const description = resultRef.current?.descripcion || '';
    const coordinates = { x: resultRef.current?.coordenadas?.economico || 0, y: resultRef.current?.coordenadas?.social || 0 };
    const candidateResult = general?.[0] || {};
    const infoLabel = <div onClick={() => navigate("/info", { state: { origin: "/resultados", result: { index: activeIndex } } })} className={`Title-Info Title-Info_small ${activeIndex === 4 ? "Title-Info_small_2" : ''}`} > <Icon className="Icon" icon="informacion-icono" />¿Cómo hicimos este análisis?</div>;
    const creditsLabel = <div className="Title-Info Title-Info_small Title-Info_small_credits" onClick={() => {
        navigate("/creditos", { state: { origin: "/resultados", result: { index: activeIndex } } })
    }}>Créditos</div>;  
    /*const handleSelect = (e) => {
        const _candidate = e.target?.value || null;
        let isValid = false;
        Object.keys(candidatesInfo)?.forEach((el, key) => {
            if (el === _candidate) {
                isValid = true;
            }
        })
        setActiveCandidate(isValid ? _candidate : null);
    }*/
    let mainResult = <div className="Container-MainResult">
        <div className="Results-PeopleCard">
            <div className="Results-PeopleCardImageFrame">
                <img src={resultImage1} alt="img" className="Results-PeopleCardImage" />
            </div>
            <div className="Results-PeopleCardContent">
                Eres la persona número <div className="Results-PeopleCardMainText">{total_respuestas}</div> en resolver este Match Presidencial.
            </div>
            <div className="Results-PeopleCardFooter">
            </div>
        </div>
        <div className="Results-PeopleCard">
            <div className="Results-PeopleCardImageFrame">
                <img src={resultImage2} alt="img" className="Results-PeopleCardImage" />
            </div>
            <div className="Results-PeopleCardContent">
            El perfil político que te acabamos de presentar lo compartes con otras <div className="Results-PeopleCardMainText">{respuestas_similares}</div> personas que han resuelto este match.
            </div>
            <div className="Results-PeopleCardFooter">
            </div>
        </div>
        <div className="Results-PeopleCard">
            <div className="Results-PeopleCardImageFrame">
                <img src={resultImage3} alt="img" className="Results-PeopleCardImage" />
            </div>
            <div className="Results-PeopleCardContent">
                <mark className="Results-MarkText"><strong>No es posible saber cuáles de las personas que respondieron el Match saldrán a votar en la segunda vuelta.</strong> Sin embargo, según una encuesta de C&C, en Colombia podrían salir a votar hasta</mark> <div className="Results-PeopleCardMainText">{ciudadanos_similares}</div> <mark className="Results-MarkText">personas que comparten tu perfil político.</mark>
            </div>
            <div className="Results-PeopleCardFooter">
            </div>
        </div>
        {infoLabel}
    </div>;
    let firstResult = (<div className="Container-FirstResult"><div className="Title Title-Results Title-Results_bold">Tu posición política</div>
        <Graphic text="Aquí estás tú" img={graphicSVG} x={((coordinates.x / 2) + 0.5) * 100} y={(-(coordinates.y / 2) + 0.5) * 100} />
        <div className="Title Title-Results Title-Results_desc">{description}</div>
        {infoLabel}
    </div>)
    //console.log("candidatesInfo?.[candidateResult.candidato]",candidatesInfo?.[candidateResult.candidato])
    let fifthResult = (<div className="Container-SecondResult">
        {candidateData('', candidatesInfo[candidateResult.candidato])}        
        <div className="Results-CandidateLink" ><a target="_blank" rel="noreferrer" href={candidatesInfo?.[candidateResult.candidato]?.link || ''}>Conócelo mejor aquí</a></div>
        <div className="Results-CandidateContainer">
            <div className="Results-BarsContainer Results-BarsContainer_2">
                {general.map((el) => {
                    let percent = null;
                    try {
                        percent = Math.round(el.similitud * 100);
                    } catch (err) {
                        console.log(err);
                    }
                    return <div className="Results-Bars">
                        <div className="Results-BarsText">{el.candidato}</div>
                        <div className="Results-BarsContent">
                            <div className="Results-BarsProgressFrame">
                                <div className="Results-BarsProgress" style={{ width: `${percent}%` }} />
                                {percent && <div className="Results-BarsLabel">
                                    {`${percent}%`}
                                </div>}
                            </div>
                        </div>

                    </div>
                })}
            </div>
            <div className="Title-InfoContainer_small">
                {infoLabel}
            </div>
            {creditsLabel}
        </div>
    </div>)
    /*let secondResult = (<div className="Container-ThirdResult">
        <div className="Title Title-Results Title-Results_desc_2">Acá puedes ver tu posición general, la de los candidatos/as y su grupo de votantes. En la última pantalla te mostraremos la candidatura más afín, a partir de tus posturas particulares</div>
        <div class="selectdiv ">
            <label>
                <select className="Results-Selector" name="select" onChange={handleSelect}>
                    {Object.keys(candidatesInfo)?.map((el, key) => {
                        return <option className="Results-SelectorItem" key={`option-${key}`} value={el.value || el}>{el}</option>
                    })}
                </select>
            </label>
        </div>
        {candidateData('Results_small', candidatesInfo[activeCandidate], true)}
        <Graphic className="Graphic_shortText" text="Tú" img={candidatesInfo?.[activeCandidate]?.heatmap || graphicSVG} x={((coordinates.x / 2) + 0.5) * 100} y={(-(coordinates.y / 2) + 0.5) * 100} />
        {infoLabel}
    </div >)*/
    let thirdResult = (<div className="Container-ThirdResult">
        <div className="Title Title-Results Title-Results_desc_4">Este es el nivel de coincidencia entre tus respuestas y las de los candidatos en <strong className="Title_italic">temas económicos</strong></div>
        <div className="Results-BarsContainer Results-BarsContainer_vertical">
            {economy.map((el) => {
                let percent = null;
                try {
                    percent = Math.round(el.similitud * 100);
                } catch (err) {
                    console.log(err);
                }
                return <div className="Results-Bars ">
                    <div className="Results-BarsContent">
                        <div className="Results-BarsProgressFrame">
                            <div className="Results-BarsProgress" style={{ height: `${percent}%` }} />
                            {percent && <div className="Results-BarsLabel">
                                {`${percent}%`}
                            </div>}
                        </div>
                    </div>
                    <div className="Results-BarsImageFrame">
                        <img className="Results-BarsImage" alt={`Imagen - ${el.candidato}`} src={candidatesInfo?.[el.candidato]?.["1x1"]} />
                    </div>
                    <div className="Results-BarsText">{el.candidato}</div>
                </div>
            })}
        </div>
        {infoLabel}
    </div>)
    let fourthResult = (<div className="Container-ThirdResult">
        <div className="Title Title-Results Title-Results_desc_4">Este es el nivel de coincidencia entre tus respuestas y las de los candidatos en <strong  className="Title_italic">temas sociales</strong></div>
        <div className="Results-BarsContainer Results-BarsContainer_vertical">
            {social.map((el) => {
                let percent = null;
                try {
                    percent = Math.round(el.similitud * 100);
                } catch (err) {
                    console.log(err);
                }
                return <div className="Results-Bars ">
                    <div className="Results-BarsContent">
                        <div className="Results-BarsProgressFrame">
                            <div className="Results-BarsProgress" style={{ height: `${percent}%` }} />
                            {percent && <div className="Results-BarsLabel">
                                {`${percent}%`}
                            </div>}
                        </div>
                    </div>
                    <div className="Results-BarsImageFrame">
                        <img className="Results-BarsImage" alt={`Imagen - ${el.candidato}`} src={candidatesInfo?.[el.candidato]?.["1x1"]} />
                    </div>
                    <div className="Results-BarsText">{el.candidato}</div>
                </div>
            })}
        </div>
        {infoLabel}
    </div>)
    /*let finalResult = (<div className="Container-ThirdResult">
        <div className="Title Title-Results Title-Results_desc_5">En esta gráfica puedes ver tu posición política comparada con la de los candidatos presidenciales y la de los 1.800 colombianos encuestados para esta app.</div>
        <Graphic className="Graphic_shortText" text="Tú" img={generalGraphic} x={((coordinates.x / 2) + 0.5) * 100} y={(-(coordinates.y / 2) + 0.5) * 100} />
        {infoLabel}
        {creditsLabel}
    </div>)*/
    let content = [firstResult, mainResult, thirdResult, fourthResult, fifthResult]
    //content = [<div>test</div>,<div>test1</div>,<div>test2</div>,<div>test3</div>,<div>test4</div>,]
    return <div className="Results">
        <SocialMediaShare className="SocialMedia_fixed" shareUrl={process.env.REACT_APP_DOMAIN} fbIcon="facebook" twitterIcon="twitter" emailIcon="envelope" />
        <div className="Results-Content">
            <Carousel
                breakpoints={{
                    '320': {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    '600': {
                        slidesPerView: 1,
                        spaceBetween: 24,
                    },
                    '1200': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                }}
                initialSlide={activeIndex}
                className={'Carousel'}
                onChange={(e) => { setActiveIndex(e?.activeIndex || 0) }}
            >
                {content}
            </Carousel>
        </div>
    </div>
        ;
}

export default Results;