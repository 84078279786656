import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Survey, StylesManager, Model, Serializer, surveyLocalization } from "survey-react";
import SocialMediaShare from "../SocialMediaShare/default";
import { Icon, buildIconSvg } from '../Core/Icon/default';
import Question1 from "../../resources/assets/examples/QuestionType1.png";
import Question1_360 from "../../resources/assets/examples/QuestionType1360.png";
import Question2 from "../../resources/assets/examples/QuestionType2.png";
import Question3 from "../../resources/assets/examples/QuestionType3.png";
import PopUp from '../PopUp/default';
import axios from 'axios';

Serializer.findProperty("question", "minWidth").defaultValue = "0";
//add a property to the base question class and as result to all questions
Serializer.addProperty("question", {
    name: "choices_categories",
    default: "",
    category: "general"
});

Serializer.addProperty("question", {
    name: "category",
    default: "",
    category: "general"
});
//Serializer.findProperty("question", "hideNumber").defaultValue = true;
StylesManager.applyTheme("modern");

//Example of adding new locale into the library.
var mycustomSurveyStrings = {
    progressText: "{0}/{1}",
    pagePrevText: "<",
    pageNextText: "Siguiente pregunta",
    completeText: "Finalizar"
};
surveyLocalization
    .locales["my"] = mycustomSurveyStrings;
var myCss = {
    footer: "Survey-Footer",
    navigation: {
        complete: "Survey-Button Survey-Button_complete",
        prev: "Survey-Button Survey-Button_prev",
        next: "Survey-Button Survey-Button_next",
        start: "Survey-Button Survey-Button_start",
        preview: "Survey-Button Survey-Button_preview",
        edit: "Survey-Button Survey-Button_edit",
    },
    "progress": "sv-progress sv-body__progress Survey-Progress",
    "progressBar": "sv-progress__bar Survey-ProgressBar",
    "progressTextUnderBar": "Survey-ProgressBarText",
};

const handlePageChange = (survey) => {
    var prev = document.getElementById('surveyPrev');

    if (prev)
        prev.style.display = (
            !survey.isFirstPage
                ? "inline"
                : "none");

    //var complete = document
    //    .getElementById('surveyComplete');
    //complete && complete.style
    //    .display = (survey.isLastPage
    //        ? "inline"
    //        : "n
}

const ratingQuestion = (name, question = '', category = '', categories = [], minRate = 1, maxRate = 6, minRateText = 'Totalmente en desacuerdo', maxRateText = 'Totalmente de acuerdo', required = true) => {
    let _result = {
        "type": "rating",
        "category": category,
        "name": name,
        "title": question,
        "isRequired": required,
        "rateMin": minRate,
        "rateMax": maxRate,
        "minRateDescription": minRateText,
        "maxRateDescription": maxRateText,
        "choices_categories": categories,
        requiredErrorText: "Campo Requerido",
        hideNumber: true
    };
    return _result;
}
const optionQuestion = (name, question = '', choices = [], categories = [], required = true,) => {
    let _result = {
        "type": "radiogroup",
        "name": name,
        "title": question,
        "isRequired": required,
        "hasNone": false,
        "colCount": 1,
        "choices": choices?.map((el, key) => { return { value: key + 1, text: el } }) || [],
        "choices_categories": categories,
        requiredErrorText: "Campo Requerido",
        hideNumber: true
    };
    return _result;
}

const choiceQuestion = (name = '', optionA = '', optionB = '',) => {
    return [{
        "type": "html",
        "name": "optionA",
        "html": `<div class="Survey-QuestionOption" ><div class="Survey-QuestionOptionTitle">Opción A</div><div class="Survey-QuestionOptionDescription">${optionA}</div></div ></div > `
    }, {
        "type": "html",
        "name": "optionB",
        "html": `<div class="Survey-QuestionOption" ><div class="Survey-QuestionOptionTitle">Opción B</div><div class="Survey-QuestionOptionDescription">${optionB}</div></div ></div > `
    }, ratingQuestion(name, "", "options",
        [buildIconSvg("menu-icon", true),
        buildIconSvg("menu-icon-2", true),
        buildIconSvg("menu-icon-3", true),
        buildIconSvg("menu-icon-3", true),
        buildIconSvg("menu-icon-2", true),
        buildIconSvg("menu-icon", true)], 1, 6, "Totalmente la opción A sobre la B", "Totalmente la opción B sobre la A", true)]
}

const surveyJson = {
    questionStartIndex: " ",
    requiredText: "",
    completedHtml: `<div id="Survey-PopUp"><div  class="Survey-PopUp"></div>
    <div class="Container Container_homepage Container_loading ">
    <div class="Homepage Homepage_loading">
    <div class="Homepage-Content">
    <div class="Homepage-Loading">
    <svg id="Componente_71_1" data-name="Componente 71 – 1" xmlns="http://www.w3.org/2000/svg" width="46.667" height="46.667" viewBox="0 0 46.667 46.667">
    <g id="Grupo_26661" data-name="Grupo 26661">
    <path id="Trazado_13729" data-name="Trazado 13729" d="M507.628,616.832A1.628,1.628,0,0,1,506,615.2V603.628a1.628,1.628,0,0,1,3.256,0V615.2A1.628,1.628,0,0,1,507.628,616.832Z" transform="translate(-484.294 -602)" fill="#d7b9ff"/>
    <path id="Trazado_13730" data-name="Trazado 13730" d="M507.628,675.5A1.628,1.628,0,0,1,506,673.871V662.295a1.628,1.628,0,0,1,3.256,0v11.576A1.628,1.628,0,0,1,507.628,675.5Z" transform="translate(-484.294 -628.832)" fill="#d7b9ff" opacity="0.5"/>
    <path id="Trazado_13731" data-name="Trazado 13731" d="M479.2,645.256H467.628a1.628,1.628,0,1,1,0-3.256H479.2a1.628,1.628,0,0,1,0,3.256Z" transform="translate(-466 -620.294)" fill="#d7b9ff"/>
    <path id="Trazado_13732" data-name="Trazado 13732" d="M537.871,645.256H526.295a1.628,1.628,0,0,1,0-3.256h11.576a1.628,1.628,0,1,1,0,3.256Z" transform="translate(-492.832 -620.294)" fill="#d7b9ff" opacity="0.7"/>
    <path id="Trazado_13733" data-name="Trazado 13733" d="M487.53,625.157a1.622,1.622,0,0,1-1.151-.477l-8.186-8.186a1.628,1.628,0,1,1,2.3-2.3l8.186,8.186a1.628,1.628,0,0,1-1.151,2.779Z" transform="translate(-471.358 -607.358)" fill="#d7b9ff"/>
    <path id="Trazado_13734" data-name="Trazado 13734" d="M529.013,666.64a1.623,1.623,0,0,1-1.151-.477l-8.186-8.186a1.628,1.628,0,0,1,2.3-2.3l8.186,8.186a1.628,1.628,0,0,1-1.151,2.779Z" transform="translate(-490.331 -626.331)" fill="#d7b9ff" opacity="0.6"/>
    <path id="Trazado_13735" data-name="Trazado 13735" d="M479.344,666.64a1.628,1.628,0,0,1-1.151-2.779l8.186-8.186a1.628,1.628,0,0,1,2.3,2.3l-8.186,8.186A1.623,1.623,0,0,1,479.344,666.64Z" transform="translate(-471.358 -626.331)" fill="#d7b9ff"/>
    <path id="Trazado_13736" data-name="Trazado 13736" d="M520.827,625.157a1.628,1.628,0,0,1-1.151-2.779l8.186-8.186a1.628,1.628,0,0,1,2.3,2.3l-8.186,8.186A1.622,1.622,0,0,1,520.827,625.157Z" transform="translate(-490.331 -607.358)" fill="#d7b9ff" opacity="0.8"/>
    </g>
    </svg>
    </div>
    <div class="Homepage-LoadingText">
    cargando resultados...
    </div>
    </div>
    </div>
    </div>`,
    showProgressBar: "top",
    pages: [
        {
            "elements": [
                {
                    "type": "html",
                    "name": "Question1Explanation",
                    "html": `<div id="Survey-PopUp"><div  class="Survey-PopUp Survey-PopUp_1"></div>
                    <div class="Container Container-Example">
                    <div class="Survey Survey_degrade">
                    <div class="Survey-PopUpIcon">
                    <svg id="Componente_60_1" data-name="Componente 60 – 1" xmlns="http://www.w3.org/2000/svg" width="82.616" height="82.616" viewBox="0 0 82.616 82.616">
  <g id="Grupo_30732" data-name="Grupo 30732">
    <circle id="Elipse_1179" data-name="Elipse 1179" cx="41.308" cy="41.308" r="41.308" fill="rgba(108,76,229,0.07)"/>
  </g>
  <g id="Grupo_30743" data-name="Grupo 30743" transform="translate(35.406 14.852)">
    <g id="Grupo_30742" data-name="Grupo 30742" transform="translate(0 17.887)">
      <path id="Trazado_16692" data-name="Trazado 16692" d="M634.811,331.426v23.818a6.69,6.69,0,0,1-6.781,6.437c-3.674-.156-6.781-2.828-6.781-6.437V331.426a6.691,6.691,0,0,1,6.781-6.438c3.674.156,6.781,2.829,6.781,6.438Z" transform="translate(-621.25 -324.983)" fill="#6c4ce5" stroke="#6c4ce5" stroke-width="1"/>
    </g>
    <circle id="Elipse_1181" data-name="Elipse 1181" cx="6.781" cy="6.781" r="6.781" transform="translate(0.085 0)" fill="#6c4ce5" stroke="#6c4ce5" stroke-width="1"/>
  </g>
</svg>

                    </div>
                    <div class="Survey-PopUpText Survey-PopUpText_noMarginBot">Elige qué tan de acuerdo estás con las afirmaciones que te presentamos a continuación.</div>
                    <div class="Survey-PopUpText Survey-PopUpText_small"><strong>Usa una escala ascendente de 1 a 6.</strong></div>
                    <picture>     
                        <source
                            srcSet="${Question1_360}"
                            media="(max-width: 600px)"
                        />               
                        <source
                            srcSet="${Question1}"
                            media="(max-width: 1200px)"
                            />
                        <img class="Survey-PopUpImage" src="${Question1_360}"/>
                    </picture>
                    <div class="Survey-PopUpText Survey-PopUpText_smallFont">* Contesta todas las preguntas para encontrar con precisión a tu candidato/a ideal.</div>
                    <button class="Survey-Button Survey-Button_next Survey-Button_example" id="SurveyButtonExample">Continuar</button> 
                    </div> 
                    </div>`
                },]
        },
        {
            "elements": [
                ratingQuestion("pregunta_1", "El gobierno debe dar beneficios a las grandes empresas para impulsar el crecimiento económico"),
                ratingQuestion("pregunta_2", "El fracking para extraer petróleo debe ser prohibido")]
        },
        {
            "elements": [
                ratingQuestion("pregunta_3", "Los recursos de la salud deberían ser administrados exclusivamente por el Estado y no por EPS privadas"),
                ratingQuestion("pregunta_4", "El gobierno debe prohibir las aplicaciones de transporte que compiten con los taxis en las ciudades, como Uber, indriver, beat y otras.")]
        },
        {
            "elements": [
                ratingQuestion("pregunta_5", "Los menores de edad que cometan delitos graves deben pagar condenas como si fueran adultos"),
                ratingQuestion("pregunta_6", "Las personas acusadas de cometer delitos deben permanecer en la cárcel hasta que se demuestre su inocencia"),
                ratingQuestion("pregunta_7", "Hay delitos tan graves que merecen la cadena perpetua")]
        },
        {
            "elements": [
                ratingQuestion("pregunta_8", "Solo deben tener derechos quienes cumplen con sus deberes"),
                ratingQuestion("pregunta_9", "Las autoridades deben poder intervenir en comunicaciones privadas de los ciudadanos para garantizar la seguridad nacional")]
        },
        {
            "elements": [
                ratingQuestion("pregunta_10", "Las empresas y el Estado deben estar obligadas a tener al menos un 50% de mujeres en sus cargos directivos"),
                ratingQuestion("pregunta_11", "Las parejas heterosexuales deben tener prioridad sobre las homosexuales al momento de adoptar niños y niñas")]
        },
        {
            "elements": [
                ratingQuestion("pregunta_12", "El bloqueo de vías es una forma legítima de protesta"),
                ratingQuestion("pregunta_13", "El ESMAD de la Policía Nacional debe ser eliminado")]
        },
        {
            "elements": [
                ratingQuestion("pregunta_14", "Me sentiría incómodo(a) si supiera que mis vecinos son desmovilizados de algún grupo armado"),
                ratingQuestion("pregunta_15", "El proceso de paz de la Habana debe ser implementado exactamente como fue acordado")]
        },
        {
            "elements": [
                ratingQuestion("pregunta_16", "El gobierno de Colombia debe volver a establecer relaciones con Nicolás Maduro como presidente de Venezuela"),
                ratingQuestion("pregunta_17", "Los migrantes venezolanos en Colombia deben tener derecho a trabajar, estudiar y recibir subsidios de programas sociales")]
        },
        {
            "elements": [
                {
                    "type": "html",
                    "name": "Question2Explanation",
                    "html": `<div id="Survey-PopUp"><div  class="Survey-PopUp Survey-PopUp_1"></div>
                    <div class="Container Container-Example">
                    <div class="Survey Survey_degrade">
                    <div class="Survey-PopUpIcon">
                    <svg id="Componente_60_1" data-name="Componente 60 – 1" xmlns="http://www.w3.org/2000/svg" width="82.616" height="82.616" viewBox="0 0 82.616 82.616">
  <g id="Grupo_30732" data-name="Grupo 30732">
    <circle id="Elipse_1179" data-name="Elipse 1179" cx="41.308" cy="41.308" r="41.308" fill="rgba(108,76,229,0.07)"/>
  </g>
  <g id="Grupo_30743" data-name="Grupo 30743" transform="translate(35.406 14.852)">
    <g id="Grupo_30742" data-name="Grupo 30742" transform="translate(0 17.887)">
      <path id="Trazado_16692" data-name="Trazado 16692" d="M634.811,331.426v23.818a6.69,6.69,0,0,1-6.781,6.437c-3.674-.156-6.781-2.828-6.781-6.437V331.426a6.691,6.691,0,0,1,6.781-6.438c3.674.156,6.781,2.829,6.781,6.438Z" transform="translate(-621.25 -324.983)" fill="#6c4ce5" stroke="#6c4ce5" stroke-width="1"/>
    </g>
    <circle id="Elipse_1181" data-name="Elipse 1181" cx="6.781" cy="6.781" r="6.781" transform="translate(0.085 0)" fill="#6c4ce5" stroke="#6c4ce5" stroke-width="1"/>
  </g>
</svg>

                    </div>
                    <div class="Survey-PopUpText Survey-PopUpText_options">Elige la respuesta que <strong>más se \n aproxime</strong> a tu opinión en cada tema.</div>
                    <img class="Survey-PopUpImage Survey-PopUpImage_options" src="${Question2}"/>
                    <div class="Survey-PopUpText Survey-PopUpText_smallFont">* Contesta todas las preguntas para encontrar con precisión a tu candidato/a ideal.</div>
                    <button class="Survey-Button Survey-Button_next Survey-Button_example" id="SurveyButtonExample">Continuar</button> 
                    </div>
                    </div> `
                }
            ],
        },
        {
            "elements": [
                optionQuestion("pregunta_18",
                    "En mi opinión el aborto:",
                    ["Siempre debe ser prohibido",
                        "Debe ser prohibido con muy pocas excepciones",
                        "Debe ser permitido en las 24 primeras semanas del embarazo",
                        "Debe ser permitido siempre"],
                    [buildIconSvg("forbidden-icon"),
                    buildIconSvg("arrows-icon"),
                    buildIconSvg("calendary-icon"),
                    buildIconSvg("unknown-icon")]
                ),
            ],
        },
        {
            "elements": [
                optionQuestion("pregunta_19",
                    "En mi opinión el uso de drogas para adultos:",
                    ["Siempre debe ser legal",
                        "Debe ser legal solo la marihuana",
                        "Debe ser legal para marihuana pero solo su uso medicinal",
                        "Todo consumo de drogas debe ser ilegal"],
                    [buildIconSvg("judge-icon"),
                    buildIconSvg("cannabis-icon"),
                    buildIconSvg("cannabis-secondary-icon"),
                    buildIconSvg("forbidden-icon")]
                ),
                optionQuestion("pregunta_20",
                    "En mi opinión la producción y venta de drogas:",
                    ["Debe ser legal para todas las drogas",
                        "Debe ser legal solo la marihuana",
                        "Debe ser legal para marihuana pero solo su uso medicinal",
                        "Debe ser ilegal para todas las drogas"],
                    [buildIconSvg("judge-icon"),
                    buildIconSvg("cannabis-icon"),
                    buildIconSvg("cannabis-secondary-icon"),
                    buildIconSvg("forbidden-icon")]),
            ],
        },
        {
            "elements": [
                optionQuestion("pregunta_21",
                    "En mi opinión las personas más pobres deben recibir un ingreso mensual del gobierno:",
                    ["Permanente y sin condiciones",
                        "Permanente pero se debe excluir a quienes no quieran trabajar ni estudiar",
                        "Solo en momentos de emergencia como el COVID-19",
                        "Todas las personas en Colombia deben recibir sus ingresos de su propio trabajo y no esperar que venga del gobierno"],
                    [buildIconSvg("money-icon"),
                    buildIconSvg("user-icon"),
                    buildIconSvg("alert-icon"),
                    buildIconSvg("office-icon")]
                )
            ],
        },
        {
            "elements": [
                optionQuestion("pregunta_22",
                    "En mi opinión la explotación de petróleo y de carbón:",
                    ["Debe hacerse solo si las comunidades locales lo aprueban",
                        "Debe hacerse solo después de oír a las comunidades, pero sin necesitar siempre su aprobación",
                        "Debe poder hacerse sin tener que buscar la opinión de las comunidades locales"],
                    [buildIconSvg("answer-good-icon"),
                    buildIconSvg("group-icon"),
                    buildIconSvg("answer-wrong-icon")]
                )
            ],
        },
        {
            "elements":
                [{
                    "type": "html",
                    "name": "Question1Explanation",
                    "html": `<div id="Survey-PopUp"><div  class="Survey-PopUp Survey-PopUp_1"></div>
                <div class="Container Container-Example">
                <div class="Survey Survey_degrade">
                <div class="Survey-PopUpIcon">
                <svg id="Componente_60_1" data-name="Componente 60 – 1" xmlns="http://www.w3.org/2000/svg" width="82.616" height="82.616" viewBox="0 0 82.616 82.616">
        <g id="Grupo_30732" data-name="Grupo 30732">
        <circle id="Elipse_1179" data-name="Elipse 1179" cx="41.308" cy="41.308" r="41.308" fill="rgba(108,76,229,0.07)"/>
        </g>
        <g id="Grupo_30743" data-name="Grupo 30743" transform="translate(35.406 14.852)">
        <g id="Grupo_30742" data-name="Grupo 30742" transform="translate(0 17.887)">
        <path id="Trazado_16692" data-name="Trazado 16692" d="M634.811,331.426v23.818a6.69,6.69,0,0,1-6.781,6.437c-3.674-.156-6.781-2.828-6.781-6.437V331.426a6.691,6.691,0,0,1,6.781-6.438c3.674.156,6.781,2.829,6.781,6.438Z" transform="translate(-621.25 -324.983)" fill="#6c4ce5" stroke="#6c4ce5" stroke-width="1"/>
        </g>
        <circle id="Elipse_1181" data-name="Elipse 1181" cx="6.781" cy="6.781" r="6.781" transform="translate(0.085 0)" fill="#6c4ce5" stroke="#6c4ce5" stroke-width="1"/>
        </g>
        </svg>
        
                </div>
                <div class="Survey-PopUpText Survey-PopUpText_noMarginBot">A continuación, leerás dos afirmaciones diferentes: A o B.</div>
                <div class="Survey-PopUpText Survey-PopUpText_a_b_option">Para indicar qué tan de acuerdo estás con A, usa los botones de la izquierda. Con B, usa los botones de la derecha.</div>
                <img class="Survey-PopUpImage Survey-PopUpImage_ab_image" src="${Question3}"/>
                <div class="Survey-PopUpText Survey-PopUpText_smallFont Survey-PopUpText_smallFont_2">* Contesta todas las preguntas para encontrar con precisión a tu candidato/a ideal.</div>
                <button class="Survey-Button Survey-Button_next Survey-Button_example" id="SurveyButtonExample">Continuar</button> 
                </div>
                </div> `
                }]

        },
        {
            "elements":
                choiceQuestion("pregunta_23",
                    "Los empresarios y personas de mayores ingresos son el motor del desarrollo del país",
                    "Las personas de mayores ingresos no están interesadas en el desarrollo del resto de la sociedad", true),

        },
        {
            "elements":
                choiceQuestion("pregunta_24",
                    "Quienes más ganan deben ser quienes financien los programas sociales pagando altos impuestos",
                    "Todas las personas deben contribuir a financiar los programas sociales pagando los mismos impuestos"),

        },
        {
            "elements":
                choiceQuestion("pregunta_25",
                    "Se debe fomentar el consumo de productos nacionales subiendo aranceles o impuestos a los productos importados",
                    "Los productos importados baratos son una buena alternativa cuando los locales se producen más caros"),

        },
        {
            "elements":
                choiceQuestion("pregunta_26",
                    "Prefiero una sociedad donde todos viven en condiciones económicas más o menos iguales",
                    "Es aceptable que en una sociedad algunos tengan mucho más que otros dependiendo de sus logros personales"),

        },
        {
            "elements":
                choiceQuestion("pregunta_27",
                    "El gobierno debe enfocar sus programas al beneficio de todas las personas de la sociedad por igual",
                    "El gobierno debe enfocar sus programas al beneficio de las personas con mayores necesidades económicas"),

        },
        {
            "elements":
                choiceQuestion("pregunta_28",
                    "El gobierno debe ser dueño de empresas productivas",
                    "El gobierno debe privatizar todas las empresas estatales"),

        },
        {
            "elements":
                choiceQuestion("pregunta_29",
                    "Es mejor pagar pocos impuestos y tener un gobierno menos costoso",
                    "Es mejor tener un gasto público alto y programas sociales amplios, así esto implique pagar más impuestos"),

        },
        {
            "elements":
                choiceQuestion("pregunta_30",
                    "Colombia debe dejar de extraer petróleo y carbón inmediatamente para disminuir el impacto en el medio ambiente",
                    "Colombia debe seguir extrayendo petróleo y carbón para continuar recibiendo ingresos e impuestos de esas industrias"),

        },
    ]
};



const DefaultSurvey = () => {
    let navigate = useNavigate();
    const { state } = useLocation();
    const { result } = state || {};
    const hasActivePopUp = result?.popUp === 1;
    const survey = new Model(surveyJson);
    survey.locale = 'my';
    const handlePrev = (survey) => {
        survey.prevPage();
    }
    const storageName = "survey_history";
    const storageOldDataName = "survey_history_old";
    const storageResultName = "survey_result_match";
    function saveSurveyData(survey, isComplete = false) {
        var data = survey.data;
        if (isComplete) {
            data.pageNo = survey.currentPageNo;
            window
                .localStorage
                .setItem(storageOldDataName, JSON.stringify(data));
            window
                .localStorage
                .removeItem(storageName, JSON.stringify(data));
        } else {
            data.pageNo = survey.currentPageNo;
            window
                .localStorage
                .setItem(storageName, JSON.stringify(data));
        }
    }

    const alertResults = useCallback(async (sender) => {
        saveSurveyData(sender, true);
        let results = sender.data;
        //console.log("Results", results);

        /*results = JSON.stringify({
            "pregunta_1": 4,
            "pregunta_2": 4,
            "pregunta_3": 5,
            "pregunta_4": 4,
            "pregunta_5": 5,
            "pregunta_6": 4,
            "pregunta_7": 4,
            "pregunta_8": 2,
            "pregunta_9": 5,
            
            "pregunta_10": 4,
            "pregunta_11": 3,
            "pregunta_12": 6,
            "pregunta_13": 4,
            "pregunta_14": 2,
            "pregunta_15": 4,
            "pregunta_16": 5,
            "pregunta_17": 2,
            "pregunta_18": 1,
            "pregunta_19": 1,
            "pregunta_20": 2,
            "pregunta_21": 3,
            "pregunta_22": 2,
            "pregunta_23": 3,
            "pregunta_24": 5,
            "pregunta_25": 4,
            "pregunta_26": 2,
            "pregunta_27": 1,
            "pregunta_28": 6,
            "pregunta_29": 5,
            "pregunta_30": 4,
            "candidato": "Gustavo Petro"
        });*/

        var config = {
            method: 'post',
            url: process.env.REACT_APP_FESCOL_API_DOMAIN,
            headers: {
                'X-API-Key': process.env.REACT_APP_FESCOL_API_KEY,
                'Content-Type': 'application/json'
            },
            data: results
        };

        try {
            const result = await axios(config);
            if(typeof window !== 'undefined'){
                window.localStorage.setItem(storageResultName, JSON.stringify(result?.data || {}));
            }
            navigate("/resultados-ejemplo", { state: { result: result.data } });
        } catch (error) {
            console.error("Error en la petición:", error);
            // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
        }
    }, [navigate]);
    survey.onComplete.add(alertResults);

    survey
        .onPartialSend
        .add(function (sender) {
            saveSurveyData(sender);
        });

    survey.sendResultOnPageNext = true;
    var prevData = window
        .localStorage
        .getItem(storageName) || null;
    if (prevData) {
        var data = JSON.parse(prevData);
        survey.data = data;
        if (data.pageNo) {
            survey.currentPageNo = data.pageNo;
        }
    }
    survey
        .onAfterRenderQuestion
        .add(function (survey, options) {
            //Hide pop up when is present
            const dataLayer = window.dataLayer || null;
            if (dataLayer) {
                dataLayer.push({ event: "page_number", eventLabel: `Preguntas_pagina_${survey.currentPageNo + 1}` })
            }
            window.scrollTo(0, 0);
            var popUpButton = document.getElementById("SurveyButtonExample");
            if (popUpButton) {
                popUpButton.onclick = function () {
                    var popUp = document.getElementById("Survey-PopUp");
                    popUp.style.display = 'none';
                    survey.nextPage();
                }
            }

            //Customize icons for radiogroup
            var category = options?.question?.propertyHash?.category || '';
            var type = options?.question?.getType() || '';
            const isRatingOptions = (type === "rating" && category === "options") || false;
            if (type === "radiogroup" || isRatingOptions) {
                var labels = options?.htmlElement?.querySelectorAll("label") || [];
                var categories = options?.question?.propertyHash?.choices_categories || [];
                labels.forEach((element, key) => {
                    const _div = document.createElement("div");
                    _div.innerHTML = `<div class="Survey-IconFrame ${type === "radiogroup" ? 'Survey-IconFrame_radiogroup' : ''}" >
                    ${categories?.[key] || ''}
                    </div > `;
                    element.appendChild(_div)
                });
            }
        });
    survey
        .onUpdateQuestionCssClasses
        .add(function (survey, options) {
            var classes = options?.cssClasses
            //console.log("Clases", classes)
            var category = options?.question?.propertyHash?.category || '';
            //var categories = options?.question?.propertyHash?.choices_categories || [];
            classes.mainRoot = "Survey-Question";
            classes.root = "Survey-QuestionRoot";
            classes.header = "Survey-QuestionHeader";
            classes.content = "Survey-QuestionContent";
            classes.footer = "Survey-QuestionFooter";
            classes.item = "Survey-QuestionItem";
            classes.itemText = "Survey-QuestionItemText";
            classes.selected = "Survey-QuestionItem_selected"
            classes.number = "Survey-QuestionNumber";
            classes.title = "Survey-QuestionTitle";
            classes.minText = "Survey-QuestionMinText";
            classes.maxText = "Survey-QuestionMaxText";
            classes.titleOnAnswer = "Survey-QuestionTitle_onAnswer";
            classes.titleOnError = "Survey-QuestionTitle_error";
            if (classes.error) {
                classes.error.root = "Survey-ErrorBox";
                classes.error.locationTop = "Survey-ErrorBox_top";
                classes.error.locationBottom = "Survey-ErrorBox_bottom";
            }
            if (category === "options" && options?.question?.getType() === "rating") {
                classes.header = "Survey-QuestionHeader_none"
                classes.itemText = "Survey-QuestionItemText_none";
            }
            if (options?.question?.getType() === "html") {
                classes.mainRoot = "Survey-Question_html"
                classes.content = "Survey-QuestionContent_html";

            }
            if (options?.question?.getType() === "radiogroup") {
                classes.mainRoot += " Survey-Question_radiogroup";
                classes.title += " Survey-QuestionTitle_radiogroup"
                classes.item += " Survey-QuestionItem_radiogroup";
                classes.content += " Survey-QuestionContent_radiogroup"
                //classes.itemControl = "Survey-QuestionControl"
                classes.label += " Survey-QuestionLabel_radiogroup"
                classes.controlLabel = "Survey-QuestionControlLabel"
                classes.materialDecorator = "Survey-QuestionControlDecorator"
                classes.itemChecked += " Survey-QuestionItemChecked"
                //classes.itemDecorator = "Survey-QuestionControlDecorator"
            }
        });
    survey
        .onUpdatePageCssClasses
        .add(function (survey, options) {
            var classes = options.cssClasses
            classes.row = "Survey-PageRow";
            classes.pageTitle = "Survey-PageTitle"
            classes.pageDescription = "Survey-PageDescription"
            if (classes.page) {
                classes.page.root = "Survey-Page";
            }
        });

    handlePageChange(survey);
    return <div>
        {!hasActivePopUp && <PopUp questionHeader="¿ALGO FALLÓ?" question="Calma, guardamos tus respuestas." questionDescription={"Retoma el cuestionario acá."} />}
        <SocialMediaShare className="SocialMedia_fixed" shareUrl={process.env.REACT_APP_DOMAIN} fbIcon="facebook" twitterIcon="twitter" emailIcon="envelope" />
        <Icon className="Icon Icon_prev" onClick={() => handlePrev(survey)} icon="chevron-left" />
        <Survey model={survey} className="Survey" css={myCss} onCurrentPageChanged={() => handlePageChange(survey)} />
    </div>;

}

export default DefaultSurvey;