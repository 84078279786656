import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ text, className, onClick,children }) => {
    const handleClick = (callback) => {
        if (callback) {
            callback();
        }
    }
    return <div className={`Button ${className || ''}`} onClick={() => handleClick(onClick)}>
        {text || ''}
        {children}
    </div>
}

export default Button;
Button.propTypes = {
    text: PropTypes.string,
};
