import React from 'react';
import PropTypes from 'prop-types';
import IcoMoon from 'react-icomoon';

const iconSet = require('../../../resources/icons/selection.json');

export const buildIconSvg = (name = '', hasAttr = false) => {
  const icons = iconSet.icons.filter(el => el.properties.name === name);
  const _result = icons.map(el => {
    const attrs = el.icon?.attrs?.map(pathAttr => {
      if (pathAttr) {
        let _result = '';
        for (const att in pathAttr) {
          _result += `${att}: ${pathAttr[att]};`;
        }
        return _result;
      }
      return null;
    });
    if (el.icon) {
      return `<svg class="Icon Icon_survey Icon_purple Icon_${name}" viewBox = "0 0 1024 1024" style = "display: inline-block; stroke: currentcolor; fill: currentcolor;" > ${el.icon.paths?.map((p, key) => `<path ${hasAttr && attrs?.[key] ? `style="${attrs[key]}"` : ''}  d="${p}"/>`).join('')}</svg > `
    }
    return null;
  })
  return _result?.[0];
}

export const Icon = ({ ...props }) => <IcoMoon iconSet={iconSet} {...props} />;

Icon.propTypes = {
  icon: PropTypes.string,
};
