import React from 'react';
import { Icon } from '../Core/Icon/default';

const Graphic = ({ className, img, x, y, text }) => {
    return <div className={`Graphic ${className || ''}`}>
        <div className='Graphic-Frame'>
            <img alt="Gráfica de resultados" className='Graphic-Image' src={img || ''} />
            <div className='Graphic-Cuadrant'>
                <div className='Graphic-Reference'>
                    <div style={{ top: `${y}%`, left: `${x}%` }} className='Graphic-Point'>
                        <Icon className='Graphic-Point_dot' icon={"user-index-icon"} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Graphic