import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon } from "../Core/Icon/default";

const Info = (props) => {
    let navigate = useNavigate();
    const { state } = useLocation();
    //let { result } = state || localStorageResult || { result: {} };
    const { title, content } = props.data || props || {};
    const handleClick = () => {
        const config = {};
        config.pathname = state?.origin || '/';
        const index = state?.result?.index || null;
        if (index) {
            config.search = `?index=${index}`;
        }
        navigate(config);
    }
    return <div className={`Info  ${props.className || ''}`}>
        <Icon className="Icon Icon_prev" onClick={() => handleClick()} icon="chevron-left" />
        <div className="Info-Title">{title}</div>
        {content?.map((el, key) => {
            return <div key={`InfoText-${key}`} className="Info-Text">{el}</div>

        })}
    </div >;
}

export default Info;