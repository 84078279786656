import React from "react";
import { useNavigate } from "react-router-dom";
import Button from '../Core/Button/default';
import { Icon } from '../Core/Icon/default';
import logoee from '../../resources/assets/logo_ee_2x.png';
import logoFescol from '../../resources/assets/logo-fescol-azul_2x.png';
import logoFiedrich from '../../resources/assets/logo_azul_fondo_transparente_2x.png';
import SocialMediaShare from "../SocialMediaShare/default";
import PopUp from '../PopUp/default';

const HomePage = (props) => {
    let navigate = useNavigate();
    return <div className={`Homepage ${props.isUpdating ? 'Homepage_updating' : ''}`}>
        {!props.isUpdating && <PopUp questionHeader="¿ALGO FALLÓ?" question="Calma, guardamos tus respuestas." questionDescription={"Retoma el cuestionario acá."} callback={() => { navigate("/matching", { state: { result: { popUp: 1 } } }) }} />}
        {!props.isUpdating && <SocialMediaShare className="SocialMedia_dark" shareUrl={process.env.REACT_APP_DOMAIN} fbIcon="facebook" twitterIcon="twitter" emailIcon="envelope" />}
        {!props.isUpdating && <div className="Homepage-Content">
            <div className="Homepage-Title" />
            <div className="Homepage-Description">{props.description || ''}</div>
            <Button className={"Button_purple Button_homepage"} text="Comencemos" onClick={() => { navigate("/matching"); }} />
            <div className="Homepage-Link"><div onClick={() => navigate("/politica-de-datos", { state: { origin: "/" } })}>{props.linkText || ''}</div></div>
            <div className="Homepage-Info Title-Info" onClick={() => navigate("/info", { state: { origin: "/" } })}><Icon className="Icon" icon="informacion-icono" />{props.info || ''}</div>
            <div className="Homepage-Brands">
                <img alt="Logo Fiedrich" className="Homepage-BrandImage" src={logoFiedrich} />
                <img alt="Logo Fescol" className="Homepage-BrandImage" src={logoFescol} />
                <img alt="Logo El Espectador" className="Homepage-BrandImage" src={logoee} />
            </div>
        </div>}
        {props.isUpdating && <div className="Homepage-Content">
            <div className="Homepage-Title" />
            <div className="Homepage-Loading">
                <Icon icon="update-icon" />
            </div>
            <div class="Homepage-LoadingText">EN ACTUALIZACIÓN</div>
            <div className="Homepage-Link Title-Link" ><a href="https://www.elespectador.com/">Volver al home</a></div>
        </div>}
    </div >;
}

export default HomePage;